exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-computer-science-index-tsx": () => import("./../../../src/pages/computerScience/index.tsx" /* webpackChunkName: "component---src-pages-computer-science-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-language-htmlcss-index-tsx": () => import("./../../../src/pages/language/htmlcss/index.tsx" /* webpackChunkName: "component---src-pages-language-htmlcss-index-tsx" */),
  "component---src-pages-language-index-tsx": () => import("./../../../src/pages/language/index.tsx" /* webpackChunkName: "component---src-pages-language-index-tsx" */),
  "component---src-pages-language-next-index-tsx": () => import("./../../../src/pages/language/next/index.tsx" /* webpackChunkName: "component---src-pages-language-next-index-tsx" */),
  "component---src-pages-language-react-index-tsx": () => import("./../../../src/pages/language/react/index.tsx" /* webpackChunkName: "component---src-pages-language-react-index-tsx" */),
  "component---src-pages-project-gatsbyblog-index-tsx": () => import("./../../../src/pages/project/gatsbyblog/index.tsx" /* webpackChunkName: "component---src-pages-project-gatsbyblog-index-tsx" */),
  "component---src-pages-project-index-tsx": () => import("./../../../src/pages/project/index.tsx" /* webpackChunkName: "component---src-pages-project-index-tsx" */),
  "component---src-pages-project-keyword-index-tsx": () => import("./../../../src/pages/project/keyword/index.tsx" /* webpackChunkName: "component---src-pages-project-keyword-index-tsx" */),
  "component---src-pages-project-market-index-tsx": () => import("./../../../src/pages/project/market/index.tsx" /* webpackChunkName: "component---src-pages-project-market-index-tsx" */),
  "component---src-pages-project-yeogi-index-tsx": () => import("./../../../src/pages/project/yeogi/index.tsx" /* webpackChunkName: "component---src-pages-project-yeogi-index-tsx" */),
  "component---src-pages-tag-tsx": () => import("./../../../src/pages/tag.tsx" /* webpackChunkName: "component---src-pages-tag-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/post_template.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */)
}

